import { Language } from './language'

const languages = Language.getTranslations()

export const statics = [
  // Navigation
  't.sports',
  't.home',
  't.multimarkets',
  't.in-play',
  't.betradar_prelive',
  't.betradar_live',
  't.casino',
  't.live_casino',
  't.top_coupons',
  't.today',
  't.link_copied',
  't.tomorrow',
  'tomorrow',
  'today',
  'now',
  't.reserved_rights',
  'tommorow',
  't.years_18_old',
  't.live_betting',
  't.back',
  't.my_account',
  't.not_verified',
  't.account_overview',
  't.balance_overview',
  't.deposit',
  't.withdraw',
  't.cashier',
  't.sportsbook_bets',
  't.casino_bets',
  't.casino_transfer_from',
  't.casino_transfer_to',
  't.active_bets',
  't.betting_history',
  't.change_password',
  't.confirm_new_pass',
  't.error_new_pass_req',
  't.error_confirm_pass_req',
  't.error_nomatch_pass',
  't.transactions',
  't.profit_loss',
  't.verification',
  't.manager_admin',
  't.my_bookmarks',
  't.session_time',
  't.articles',
  'Live',
  'Prelive',
  'Forgot?',
  't.deposit_withdraw',
  't.sportsbook',
  't.others',
  't.login_error',
  't.parlays_board',
  't.financies',
  't.betting_stats',
  't.betfair_bets',
  't.fancy_bets',
  't.last_winners',
  't.dabba_bets',
  't.session_bets',
  't.casino_bets',
  't.sportbook_bets',
  't.make_deposit',
  't.parlay',
  't.related-select',
  't.parlay-error',
  't.make_withdraw',
  't.other',
  't.matchbook_bets',
  't.selfexchange_bets',
  't.money_transfer',
  't.nav_my_account',
  't.how_to_get_refer',
  't.how_to_get_refer_1',
  't.how_to_get_refer_2',
  't.how_to_get_refer_3',
  't.how_to_get_refer_4',
  't.copy_link',
  't.refer_a_friend',
  't.refer_nav_link',
  't.bonus_locked',
  't.reversed_rights',
  't.play_responsibility',
  't.yesrs_18_old',
  't.min_val_is',
  't.nav_cashier',
  't.nav_my_bets',
  'nav_my_bets',
  't.cricket_betting',
  't.online_casino',
  't.my_details',
  'Bad credentials',

  // Sportsbook
  't.featured_events',
  't.minutes',
  't.create_coupon',
  't.print',
  'Set',
  'Quarter',
  'Period',
  't.processing',
  'There are currently no events for this sport',
  'No such event',

  // Betslip
  'One click betting',
  't.betting_slip',
  't.total_stake',
  't.possible_winnings',
  't.parlay',
  't.save',
  't.saving',
  't.reset',
  't.submit',
  't.submitting',
  't.message.parlay_cant_contains_bets_from_one_event',
  't.message.remove_parlay_extra_picks',
  't.double_chance_bets_limit_reached',
  't.message.double_chance_limit',
  't.allow_all_odd_change',
  'Your bets were successfully saved',
  'Bets were successfully placed!',
  'Something went wrong',
  'All bets were successfully added to betslip',
  'Some bets are suspended and were not added to betslip',
  't.wrong_source',
  'Please authentificate!',
  't.please_login',
  'bet.validate.not_enough_money',
  'bet.validate.exposure_limit',
  't.closed',
  't.suspended',
  't.parlay_bonus',
  'You have no bets on this market',
  'backer’s odds',
  'backer’s stake',
  'Make your selection(s) on the left by clicking on the odds.',
  't.max_stake',
  't.no_active_selections',

  // Market scopes
  'full_event',
  '10-minutes',
  'first-half',
  'second-half',
  '1st Half',
  '2nd Half',
  'Full Time',
  '1st Set',
  '2nd Set',

  // Registration
  't.registration',
  't.registration_complete_title',
  't.registration_complete_message',
  't.registration_username',
  't.registration_repeat_password',
  't.registration_password',
  't.registration_gender',
  't.registration_firstname',
  't.registration_lastname',
  't.registration_birthday',
  't.back_to_login',
  't.registration_i_agree',
  't.registration_of_service',
  't.registration_country',
  't.registration_city',
  't.registration_postcode',
  't.registration_address',
  't.registration_email',
  't.registration_phone',
  't.registration_terms',
  't.registration_house',
  't.registration_zip',
  't.registration_enter_phone_number',
  't.registration_enter_verification_code',
  't.registration_receive_code_question',
  't.registration_resend_code',
  't.registration_confirm_email',
  't.registration_confirm_password',
  't.next',
  't.currency',
  't.male',
  't.female',
  't.year',
  't.month',
  't.day',
  't.select_address',
  't.please_enter_a_valid_zip_code',
  't.terms_and_conditions_link',
  't.enter_otp_code',

  // Login page

  't.email',

  // Password Resetting
  't.reset_password',
  't.reset_password_instructions_sent',
  't.set_new_password',
  't.reset_password_success_message',
  'user.word.resetting.invalid_username',
  'user.word.resetting.password_already_requested',
  'fos_user.password.mismatch',

  // Layout
  'back_on_top',
  't.log_in',
  't.log_out',
  't.login',
  't.login_name',
  't.login_name_fairwaybet',
  't.login_password',
  't.live_betting',
  't.betslip',
  't.select_wallet',
  't.deposit_now',

  // Languages
  ...languages,

  // Errors
  `This value should be the user\'s current password.`,
  'user.password.mismatch',
  't.error',
  't.login_error',

  /* Account overview */
  't.overview_username',
  't.overview_lastName',
  't.overview_firstName',
  't.overview_code',
  't.overview_email',
  't.overview_phone',
  't.overview_birthday',
  't.overview_country',
  't.overview_state',
  't.overview_city',
  't.overview_street',
  't.overview_currency',
  't.overview_zip',
  't.account_avatar',
  't.upload_avatar',
  't.edit_profile',
  't.save_changes',
  't.cancel',
  't.cancel_withdraw',
  't.loading',

  /* Account balance overview */
  'wst.account.playeraccount',
  'wst.account.bonusaccount',
  'wst.account.agentplayeraccount',
  't.balance_activate',
  'Bonus Progress',
  'Name',
  't.bonus_company',
  't.bonus_account',
  't.years_18_old',
  't.player_account',
  't.complete',
  'Bonus Info',
  't.reserved_rights',
  'Period',
  'amountCycles',
  'excludedMarketName,s',
  'excludedSourceIds,',
  'exclude,dSportIds',
  'minOdd',
  'playerAmountCycles',
  't.bonus_code',
  't.manager_balance',
  't.net_deposit',

  /* Password change */
  't.password_change',
  't.current_password',
  't.enter_new_password',
  't.repeat_new_password',
  't.old_password',
  't.new_password',
  't.repeat_password',
  't.change',

  /* Account verification */
  't.photoStatuses.null',
  't.photoStatuses.__wait_approval',
  't.photoStatuses.__verified',
  't.photoStatuses.__declined',
  't.status',
  't.select_file',
  'I agree that these documents will be used to verify my identity.',

  /* Payments */
  't.name',
  't.street',
  't.street_number',
  't.city',
  't.zip',
  't.country',
  't.bic',
  't.iban',
  't.comment',
  't.phone_sender',
  't.withdraw_skip_sms',
  't.sms_validation',

  /* Account transactions */
  't.transactions.id',
  't.transactions.types',
  't.transactions.amount',
  't.transactions.status',
  't.transactions.date',
  't.transactions.actions',

  't.transactions.balance',
  't.transactions.bonus',
  't.transactions.transactionType',
  't.transactions.debits',
  't.transactions.credits',

  't.load_more',

  '__bet_placed',
  '__bet_rejected',
  '__bet_settled',
  '__bet_lose_recalculate',
  '__bet_win_recalculate',
  '__bet_recalculate',
  '__bet_invalid_recalculate',
  '__bet_update_liability',
  '__bet_commission',
  '__payment',
  '__internal',
  '__bonus',
  '__bonus_win',
  '__bankroll',
  '__payment_commission',
  '__wait_approve',
  'wirecard',

  't.transactions.payment',
  't.transactions.internal',

  /* Profit & Loss */
  't.start_time',
  't.total',
  't.commission',
  'sport',

  't.date_start',
  't.date_end',
  't.any',

  /* Account bettings */
  't.id',
  't.type',
  't.events',
  't.status_comment',
  't.odds',
  't.possible_winnings',
  't.bonus',
  't.placed',
  't.result',
  't.lose',
  't.win',
  't.refund',
  't.date',
  't.my_bets',
  't.market',
  'selection',
  't.bet_id',
  't.bet_placed',
  't.bet_settled',
  't.odds_req',
  't.size_matched',
  't.date_matched',
  't.cancel',
  't.bets',
  't.total_payout',
  't.settled_date',
  'Profit',
  't.active',
  't.new',
  't.filter',
  't.total_sales',
  't.total_win',
  't.total_count',
  't.amount',
  't.history',
  't.no_results_found',
  't.live',
  't.prelive',
  't.single',
  't.unknown',
  't.clear_all',
  't.clear',
  't.select_all',
  'dabba Bets',

  /* Account Responsible Gambling */
  't.responsible-gambling',
  't.select_exclusion',
  't.suspend_for',
  't.months',
  't.years',
  't.suspend_permanently',
  't.select_period',
  't.no_reminder',
  't.hour',
  't.hours',
  't.success',
  't.select_timeout',
  't.timeout_for',
  't.week',
  't.weeks',
  't.session_time_out',
  't.limit_access',

  /* Casino */
  't.play',
  't.play_for_fun',
  't.all',
  't.sort_by',
  't.provider',
  't.casino_sort_alphabetic_asc',
  't.casino_sort_alphabetic_desc',
  't.casino_sort_relevancy_desc',
  't.back_to_catalog',
  't.pending_withdraw',

  't.latest_winners',
  't.featured_games',
  't.unknown_game',
  't.just_won',
  't.in',
  't.this_game_is_temporary_unavailable',

  /* Live event */
  't.points',
  't.started_at',
  'You will be redirected to random event in',
  'Event is suspended',
  'Game',
  'Ordinary time',
  'Full Event',
  'There are no active events in this moment',
  't.no_events_fetched',
  'This event temporarily has no active markets',

  /* Payment */
  't.payment_deposit',
  't.deposit_funds',
  't.payment_withdraw',
  't.address',
  't.transaction_processing',
  't.transaction___hold',
  't.transaction___canceled',
  't.transaction___accepted',
  't.transaction___error',
  't.transaction___wait_approve',

  /* Prelive print */
  't.from',
  't.to',

  /* COUPON CHECK */
  't.coupon_check',
  't.insert_coupon_code',
  't.go',
  't.paid',
  't.not_paid',
  't.coupon_id',
  't.pay',
  't.bet_pay_success',
  't.close',
  't.invalid',

  /* fast sport */
  't.fast_sport',
  't.go',
  'enter access code',

  /* Searchbar */
  't.exchange',
  'Characters length have to be 3 or greater',
  'Type search phrase here',

  /* prelive-event-row > back-lay > market */
  'go to event to see selections',

  /* exchange */
  'Go to my bets',
  'Sport Highlights',
  'There are currently no events for this sport',
  'All Sports',
  'Suspended',
  'Low liquidity',
  'Back',
  'Lay',
  'Matched',
  'View full market',
  'Home',
  'In-play',
  'now',
  'today',
  'tommorow',
  'Singles',
  'Open bets',
  'Liability',
  'Errors',
  'Placed (matched)',
  'Waiting (unmatched)',
  'Back (Bet For)',
  'Lay (Bet Against)',
  'Update',
  'Updating',
  'Search',
  't.show-more',
  't.switch_account',
  't.main_account',
  'Place bets!',
  't.allow_all_odds_changes',
  't.delete_all',
  't.betslip',
  't.profit',
  't.no_highlighted_events_or_error',
  't.currently_no_events',
  't.market_data_loading',
  't.market_data_unavailable',
  't.confirm',
  't.edit_bets',
  't.confirm_bets_before_placing',
  'Rotate screen to see market depth',
  'Not all bets were added to betslip',
  't.exposure',
  'Bet placement in progress',
  't.ball_running',
  't.non_runner',
  'notstarted',
  'inprogress',
  'live',
  'This event currently not available. Choose another event from left menu.',
  'Chose market from menu on the left.',
  't.matched',
  't.unmatched',
  't.settled',
  'Bet was pinned',

  /* Footer */
  't.footer_about_us',
  't.footer_contact_us',
  't.footer_help',
  't.footer_terms_and_conditions',
  't.footer_sports_betting_rules',
  't.footer_rules',
  't.footer_privacy_policy',
  't.footer_promotions',
  't.footer_faq',
  't.footer_support',
  't.footer_refund_policy',
  't.footer_costs_and_fees',
  't.footer_how_to_bet',
  't.footer_complaints',
  't.footer_responsible_gaming',
  't.footer_play_responsibly',
  't.footer_deposit_option',
  't.footer_latest_news',
  't.footer_tips',
  't.footer_affiliates',

  // Weekdays
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',

  // odds formats
  'user.model.const.__odd_format_europe',
  'user.model.const.__odd_format_uk',
  'user.model.const.__odd_format_us',
  'user.model.const.__odd_format_malay',
  'user.model.const.__odd_format_hongkong',
  'user.model.const.__odd_format_indonesian',
  'user.model.const.__odd_format_indian',

  // print coupon
  't.coupon.coupon',
  't.coupon.type_of_bet',
  't.coupon.single',
  't.coupon.parlay',
  't.coupon.event',
  't.coupon.odds',
  't.coupon.total_odds',
  't.coupon.stake',
  't.coupon.win',
  't.coupon.total_payout',
  't.coupon.address',
  't.coupon.notice',
  't.coupon.possible_winning',
  't.coupon.bonus',

  // parlay board
  'parlays_board',
  't.player',
  't.selections',
  't.total_odds',
  't.details',
  't.category',
  't.competition',
  't.event_name',
  't.total_odds',
  't.event_start_date',
  'selection',
  't.no_active_parlays',

  't.leader_board',
  't.win_rate',
  't.active_parlays',
  't.parlay_history',
  't.show_favorites_only',
  't.add_to_betslip',

  // Responsible Gambling
  't.session-reminder',
  't.settings',
  't.self-exclusion',
  't.and',
  't.session_duration_reminder',
  't.deposit-limit',
  't.period',
  't.select_amount',
  't.no_limit',
  't.per_24_hours',
  't.per_7_days',
  't.per_month',
  't.confirm_deposit_limit',
  't.loss-limit',
  't.confirm_loss_limit',
  't.stake-limit',
  't.confirm_stake_limit',
  't.time_out',
  't.spend-limit',
  't.confirm_spend_limit',
  't.deposit_limit_started_at',
  't.loss_limit_started_at',
  't.stake_limit_started_at',

  // Achievements
  't.achievements',
  't.your_achievements',
  't.group',

  't.error_min_pass',
  't.join',
  't.registration_username_or_email',
  't.registration_password',
  't.registration_forgot_password',
  't.registration_enter_code',
  't.registration__code-resend-btn',
  't.registration_verify_code',
  't.error_min_pass',
  't.error_pass_req',
  't.error_gender_req',
  't.registration_enter_pass',
  't.error_firstname_req',
  't.error_lastname_req',
  't.error_code_req',
  't.error_email_req',
  't.error_street_req',
  't.error_zip_req',
  't.error_city_req',
  't.error_phone_req',
  't.bonus_history',
  't.all_groups',
  't.all_providers',
  't.legal_info',
  't.help',
  't.promotions',
  't.affiliates',
  't.online_betting',
  't.online_casino_guides',
  't.verify_code',
  't.confirm_logout',
  't.accept',
  't.field_required',
  't.confirm_new_password',
  't.show-more',
  't.final_step_join',
  't.no_selections_betslip',
  't.enter_email',
  't.accept_terms',
  't.bonus_company',
  't.bonus_complete_message',
  't.compete',
  't.complete',
  't.prize_draw',
  't.transactions.bonus',
  't.now',
  't.today',
  't.tomorrow',
  't.pix_value',
  't.pix_key_type',
  't.select_payment',
  't.your_balance',
  't.to_withdraw',
  't.the_withdraw',
  't.date_settle',
  't.success_register',
  'Final Step',
  't.place_bet',
  't.to-return',
  't.open_bets',
  't.stake',
  't.money',
  't.show-all',
  't.durationAfterActivation',
  'now',
  't.upcoming_events',
  't.in_30_minutes',
  't.in_one_hour',
  't.featured_competitions',
  't.featured',
  't.no_live_events_for_this_sport_right_now',
  't.games',
  't.switch_tabs_exch',
  't.switch_tabs_sportsbook'
]
