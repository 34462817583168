import 'core-js/es6/promise'
import 'core-js/es7/reflect'
import 'tslib'

import React, { lazy, Suspense, useEffect } from 'react'
import { configure } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import qs from 'query-string'
import { AppStore, AppContext } from 'core'
import { AllowedRoute } from 'core/user/AllowedRoute'
import 'utils/polyfill/css-custom-properties'
import 'assets/styles/index.css'
import 'react-datepicker/dist/react-datepicker.css'

import {
  EXCHANGE_TYPE_BETFAIR,
  EXCHANGE_TYPE_MATCHBOOK,
  EXCHANGE_TYPE_SELFEXCHANGE,
  EXCHANGE_TYPE_DABBA,
} from 'modules/exchange/interface'
import { PaymentSuccess } from './account/payment/payment-success'
import { NotifierComponent } from '../core/notifier'
import { PaymentResult } from './account/payment/payment-result'
import { facebookPixelCode } from '../utils/facebookPixel'
import {getCookie} from '../utils/storage'
import {TelegramLink} from './layout/telegram-link/telegram-link.component'

const getLogin = (project: string) =>
  lazy(() =>
    import(/* webpackChunkName: "[request]" */ `projects/${project}/layouts/layout/login`).catch(e =>
      import(/* webpackChunkName: "default-login" */ './login'),
    ),
  )
const getLayout = (project: string) =>
  lazy(() =>
    import(/* webpackChunkName: "[request]" */ `projects/${project}/layouts/layout/`).catch(e =>
      import(/* webpackChunkName: "default-layout" */ './layout'),
    ),
  )
const getAccount = (project: string) =>
  lazy(() =>
    import(/* webpackChunkName: "[request]" */ `projects/${project}/layouts/account`).catch(e =>
      import(/* webpackChunkName: "default-account" */ './account'),
    ),
  )
const getStaticPage = (project: string) =>
  React.lazy(() =>
    import(/* webpackChunkName: "[request]" */ `projects/${project}/layouts/static-page`).catch(e =>
      import(/* webpackChunkName: "default-static-page" */ './layout/static-page'),
    ),
  )
const Registration = lazy(() => import(/* webpackChunkName: "default-registration" */ './registration'))
const PasswordReset = lazy(() =>
  import(/* webpackChunkName: "default-password-reset" */ './password/password-reset.component'),
)
const PasswordUpdate = lazy(() =>
  import(/* webpackChunkName: "default-password-update" */ './password/password-update.component'),
)
const Confirmation = lazy(() => import(/* webpackChunkName: "default-confirmation" */ './confirmation'))
const getSportsbook = (project: string) =>
  lazy(() =>
    import(/*webpackChunkName: "sportsbook" */ `projects/${project}/layouts/sportsbook`).catch(e =>
      import('./sportsbook3'),
    ),
  )
const Casino = lazy(() => import(/* webpackChunkName: "casino" */ 'modules/casino'))
const Main = lazy(() => import(/* webpackChunkName: "mainpage" */ 'modules/mainpage'))
const Exchange = lazy(() => import(/* webpackChunkName: "exchange" */ 'modules/exchange'))
const ParlaysBoard = lazy(() =>
  import(/* webpackChunkName: "parlays-board" */ 'modules/sportsbook3/parlays-board'),
)
const LeaderBoard = lazy(() =>
  import(/* webpackChunkName: "parlays-board" */ 'modules/sportsbook3/leader-board'),
)
const getMetaInfo = (project: string) =>
  lazy(() =>
    import(/*[request]" */ `projects/${project}/layouts/meta`).catch(() =>
      import(/* webpackChunkName: "default-meta" */ 'modules/meta'),
    ),
  )

configure({ enforceActions: 'observed' })

interface Props extends AppStore {
  theme?: string
}

export const App = observer(({ theme, ...stores }: Props) => {
  const loc = window.location.href
  if (loc.includes('edufind')) {
    window.location.href = 'https://24betting.com/'
  }
  if (loc.includes('triction')) {
    window.location.href = 'https://24betting.com/'
  }
  const { config, history, user, http } = stores
  const { project } = config.data
  const front = config.front
  const resultTheme = theme || front.theme

  const MetaInfo = getMetaInfo(project)
  const StaticPage = getStaticPage(project)
  const Account = getAccount(project)
  const Layout = getLayout(project)
  const Sportsbook = getSportsbook(project)
  const Login = getLogin(project)
  const redirect = () => {
      if (window.location.host === 'betstarexch.com') {
          if (getCookie('mark')) {
              http.wst.get('user/auths').then((resp) => {
                  window.location.href = 'https://winstarexch.com/casino?authID=' + resp.data
              })
          } else {
              window.location.href = 'https://winstarexch.com/casino'
          }
      }
  }

  const loginBySessionId = () => {
      if (window.location.host === 'winstarexch.com') {
          const url = new URL(window.location.href)
          const sessionId = url.searchParams.get('authID')
          if (sessionId) {
              setTimeout(() => {
                  http.wst.post('login-by-session-id', {authID : sessionId}).then((resp) => {
                      window.location.href = 'https://winstarexch.com/casino'
                  })
              }, 3000)
          }
      }
  }

  useEffect(() => {
    if (process.env.STAGE === 'prod') {
      facebookPixelCode()
    }
    redirect()
    loginBySessionId()
  }, [])
  // Save affiliate codes. They needed at registration
  useEffect(() => {
    const query = qs.parse(history.location.search)
    if (query.btag && window.localStorage.getItem('btag') === null) {
      if (query.btag[query.btag.indexOf('s2') - 1] === '-') {
        window.localStorage.setItem('btag', query.btag.slice(0, query.btag.indexOf('s2') - 1) as string)
      } else {
        window.localStorage.setItem('btag', query.btag as string)
      }
    }

    if (query.qtag && window.localStorage.getItem('qtag') === null) {
      window.localStorage.setItem('qtag', query.qtag as string)
    }

    // Post Affiliate Pro
    if (query.a_aid && window.localStorage.getItem('a_aid') === null) {
      window.localStorage.setItem('a_aid', query.a_aid as string)
    }

    if (query.btag && query.btag[query.btag.indexOf('s2') - 1] === '-' && window.localStorage.getItem('s2') === null) {
      window.localStorage.setItem('s2', query.btag.slice(query.btag.indexOf('s2') + 3) as string)
    }

    if (query.s2 && window.localStorage.getItem('s2') === null) {
      window.localStorage.setItem('s2', query.s2 as string)
    }
  }, [])

  const isRedesign = config.data.frontConfiguration.theme === 'netflixlike'
  const isAppletvlike = config.data.frontConfiguration.theme === 'appletvlike'

  const { indexRoute } = front
  return (
    <div className={`theme-${resultTheme} project-${project}`}>
      <Suspense fallback={null}>
        <MetaInfo history={history} project={project} />
      </Suspense>
      <AppContext.Provider value={stores}>
        {front['login.required'] && !user.isLoggedIn ? (
          <Router history={history}>
            <Suspense fallback={null}>
              <Switch>
                <Route
                  path="/login"
                  render={(props) => (
                    <Login {...props} />
                  )}
                />
                <Route
                  path="/player/reset_password/:token"
                  render={(props) => {
                    return <Redirect
                      to={`/login?modal=change-password&token=${props.match.params.token}`} />
                    // return <PasswordUpdate {...props} />
                  }}
                />
                <Route path="/player/confirm/:token" render={props => <Confirmation {...props} />} />
                <Redirect from="/" to={'/login'} />
              </Switch>
            </Suspense>
          </Router>
        ) : (
          <Router history={history}>
            <Suspense fallback={null}>
              <Layout project={project}>
                <Suspense fallback={null}>
                  <Switch>
                    <Route
                      path="/account"
                      render={props => (
                        <AllowedRoute ifCan="seeAccountPage">
                          <Account project={project} {...props} />
                        </AllowedRoute>
                      )}
                    />
                    <Route path="/pages/:slug" render={props => <StaticPage {...props} />} />
                    <Route path="/get-telegram-link" render={props => <TelegramLink {...props}/>} />
                    <Route
                      path="/registration"
                      render={props => (
                        <AllowedRoute ifCan="seeRegistrationPage">
                          <Registration {...props} />
                        </AllowedRoute>
                      )}
                    />
                    <Route
                      path="/reset_password"
                      render={props => (
                        <AllowedRoute ifCan="seeLoginForm">
                          <PasswordReset {...props} />
                        </AllowedRoute>
                      )}
                    />
                    <Route
                      path="/player/reset_password/:token"
                      render={(props) => {
                        if (isRedesign || isAppletvlike) {
                          return <Redirect
                            to={`${indexRoute}?modal=change-password&token=${props.match.params.token}`} />
                        }
                        return <PasswordUpdate {...props} />
                      }}
                    />
                    <Route path="/player/confirm/:token" render={props => <Confirmation {...props} />} />

                    <Route
                      path="/casino"
                      render={() => <Casino onSignUpClick={() => history.push('/registration')} />}
                    />
                    {config.data.project === '24betting' && front.indexRoute === '/ ' &&
                      <Route
                        path="/ "
                        render={() => <Main />}
                      />
                    }
                    {front['sportsbook.sources'].map(source => [
                      <Route
                        path={source.prelive.url}
                        render={props => (
                          <Sportsbook
                            liveSource={parseInt(source.live.source)}
                            preliveSource={parseInt(source.prelive.source)}
                            prefixUrl={source.prelive.url}
                            livePrefixUrl={source.live.url}
                            isBetradar={source.prelive.types.indexOf('betradar') !== -1}
                            type="prelive"
                            {...props}
                          />
                        )}
                      />,
                      <Route
                        path={source.live.url}
                        render={props => (
                          <Sportsbook
                            liveSource={parseInt(source.live.source)}
                            prefixUrl={source.live.url}
                            isBetradar={source.live.types.indexOf('betradar') !== -1}
                            type="live"
                            {...props}
                          />
                        )}
                      />,
                    ])}
                    {config.data.betfair && (
                      <Route
                        path={`${config.data.betfair!.prefixUrl}/:contentType?/:itemId?`}
                        render={props => (
                          <Exchange
                            prefixUrl={config.data.betfair!.prefixUrl}
                            exchangeApiType={EXCHANGE_TYPE_BETFAIR}
                            {...props}
                          />
                        )}
                      />
                    )}
                    {config.data.dabba && (
                      <Route
                        path={`${config.data.dabba!.prefixUrl}/:contentType?/:itemId?`}
                        render={props => (
                          <Exchange
                            prefixUrl={config.data.dabba!.prefixUrl}
                            exchangeApiType={EXCHANGE_TYPE_DABBA}
                            {...props}
                          />
                        )}
                      />
                    )}
                    {config.data.selfexchange &&
                      config.data.selfexchange.sources.map(source => (
                        <Route
                          path={`${source.url}/:contentType?/:itemId?`}
                          key={source.url}
                          render={props => (
                            <Exchange
                              prefixUrl={source.url}
                              exchangeApiType={EXCHANGE_TYPE_SELFEXCHANGE}
                              source={source.source}
                              {...props}
                            />
                          )}
                        />
                      ))}
                    {config.data.matchbook && (
                      <Route
                        path={`${config.data.matchbook!.prefixUrl}/:slug?/:slug1?/:slug2?/:slug3?`}
                        render={props => (
                          <Exchange
                            prefixUrl={config.data.matchbook!.prefixUrl}
                            exchangeApiType={EXCHANGE_TYPE_MATCHBOOK}
                            {...props}
                          />
                        )}
                      />
                    )}
                    <Route path="/parlaysboard" render={props => <ParlaysBoard {...props} />} />
                    <Route path="/leaderboard" render={props => <LeaderBoard {...props} />} />
                    <Route path="/payment/fluttermoney/success">
                      <PaymentSuccess />
                    </Route>
                    <Route path="/payment/:type/:id">
                      <PaymentResult />
                    </Route>
                    {!user.isLoggedIn && (
                      <Route path="/login">
                        <Login />
                      </Route>
                    )}
                    <Redirect from="/" to={front.indexRoute} />
                  </Switch>
                </Suspense>
              </Layout>
            </Suspense>
          </Router>
        )}
        <NotifierComponent />
      </AppContext.Provider>
    </div>
  )
})
