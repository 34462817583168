import { action, computed, observable, runInAction } from 'mobx'
import { ConfigStore, HttpStore, SynchronizedHistory, NotifierStore, WSStore } from 'core'
import { Action, Role, Permissions } from './permissions'
import { Account, AccountJSON } from './account'
import { Location, LocationJSON } from './location'
import { Settings, SettingsJSON } from './settings'
import { UserStatus } from './statuses'
import {is24Betting} from '../../utils/24betting'
import { FrontNotification } from './FrontNotification'

export class UserStore {
  @observable private statuses: UserStatus[]
  @observable private role: Role

  /** All user roles */
  @observable private roles: string[]

  /** All user accounts **/
  @observable accounts: AccountJSON[]

  /** User active account */
  @observable account: Account

  /** Vouchers amount for some managers */
  @observable vouchers: Money

  @observable bonusBalance: number

  /** User settings */
  @observable settings: Settings | null = null

  /** User permissions */
  @observable permissions: Permissions

  /** User location */
  @observable location: Location

  @observable frontNotificationText: string
  @observable frontNotificationId: string

  @observable username: string
  @observable email: string
  @observable tfaEnabled: boolean
  @observable id: number | null = null
  @observable logInError: string | null = null
  @observable lastLogin: string | null = null
  @observable licenseId: number | null = null
  @observable createdAt: string = ''
  btag?: string

  /** Http service */
  constructor(
    private config: ConfigStore,
    private http: HttpStore,
    private history: SynchronizedHistory,
    private notifier: NotifierStore,
    private ws: WSStore,
  ) {
    this.permissions = new Permissions(this.config)
  }

  @computed
  get isReady(): boolean {
    return !!this.role
  }

  @computed
  get isLoggedIn() {
    return this.role !== 'guest' && this.role !== undefined
  }

  @action
  public logIn(username: string, password: string) {
    const body = {
      _username: username,
      _password: password,
    }

    return this.http.wst
      .post('login_check', body)
      .then(() => {
        this.load()
        return true
      })
      .catch(({ response }) => {
        const error = response.data.error || response.data.message
        this.notifier.error(error)
        runInAction(() => {
          this.logInError = error
        })
        return false
      })
  }

  @action
  public async logOut() {
    await this.http.wst.get('logout')
    if (is24Betting(this.config)) {
      runInAction( () => {
      const script2 = document.createElement('script')
      script2.innerHTML = `
    window.OneSignal = window.OneSignal || [];
    OneSignal.push(function() {
      OneSignal.removeExternalUserId();
          console.log("externalUserId: ", externalUserId);
    });
    `
      script2.type = 'text/javascript'
      document.head.appendChild(script2)
      }
     )
    }
    this.clear()
    this.redirectToDefaultRoute()
  }



  @action
  public updateBalances() {
    if (this.hasRole('manager')) {
      this.loadVouchers()
    } else {
      this.loadAccount('par=7')
    }
  }

  @action
  public checkIsFirstDeposit() {
    return this.http.wst.get('player/check-first-deposit')
  }

  /// PERMISSIONS
  /** Check access manager to admin */
  public get canAccessManagerAdmin() {
    return this.hasRole('manager') && this.roles.length > 1
  }

  /** Get default user route */
  public get defaultRoute() {
    return this.permissions.getDefaultRoleRoute(this.role)
  }

  /** Check if user has verified status */
  public isVerified(): boolean {
    // TODO find another way to check verification
    return this.statuses.indexOf(UserStatus.ACTIVE) !== -1
  }

  public isBetsDisabled(): boolean {
    return this.statuses && this.statuses.indexOf(UserStatus.STATUS_WITHOUT_BETS) !== -1
  }

  public isWithdrawOnly(): boolean {
    return this.statuses && this.statuses.indexOf(UserStatus.STATUS_WITHDRAW_ONLY) !== -1
  }

  public isLoginOnly(): boolean {
    return this.statuses && this.statuses.indexOf(UserStatus.SUSPENDED) !== -1
  }


  /** Check user ability to do some action */
  public can(action: Action): boolean {
    return this.permissions.can(this.role, action)
  }

  /** Validates current route. */
  // TODO repair route validtion
  public validateRoute(route: string): void {
    // if (!this.permissions.canBeOn(this.role, route)) {
    //   this.redirectToDefaultRoute()
    // }
  }

  /** If login required, redirect to login page, else redirect to default route */
  private redirectToDefaultRoute() {
    if (this.config.front['login.required'] && !this.isLoggedIn) {
      // this.history.push('/login')
    } else if (this.role === 'masteragent' || this.role === 'supermasteragent') {
      const origin =
        window.location.hostname === 'localhost' ? `//${this.config.domain}` : window.location.origin
      window.location.href = origin + '/agent'
    } else if (window.location.pathname !== this.defaultRoute) {
      this.history.push(this.defaultRoute)
    }
  }

  /** Check user role */
  hasRole(role: Role): boolean {
    return this.role === role
  }

  // HTTP USER INFO

  loadAccount(param = '') {
    return this.http.wst.get('account/active?' + param).then((resp) => this.setAccount(resp.data))
  }

  loadBonus() {
    return this.http.wst.get('bonus/balance').then(
        (resp) => {
          this.setBonusBalance(resp.data.bonus)
        })
  }

  wsLoadAccount() {
    const { project } = this.config.data
    this.ws.subscribeChanel(
      `user:${project}:BalanceUpdateEvent:${this.account.id}`,
      (resp: any) => {
        try {
          this.setAccountBalance(resp?.data)
        } catch (err) {
          console.error(err)
        }
      })
    this.ws.subscribeChanel(
      `user:${project}:ForecastUpdateEvent:${this.account.id}`,
      (resp: any) => {
        try {
          this.setAccountExposure(resp?.data)
        } catch (err) {
          console.error(err)
        }
      })
    this.ws.subscribeChanel(
      `user:${project}:userFrontNotification:${this.account.id}`,
      (resp: any) => {
        try {
          localStorage.setItem('isNotifyActive', 'true')
          this.setFrontNotificationText(resp?.data)
          this.setFrontNotificationId(resp?.data)
        } catch (err) {
          console.error(err)
        }
      })
    this.ws.subscribeChanel(
        `user:${project}:BonusBalanceUpdateEvent:${this.account.id}`,
        (resp: any) => {
          try {
            this.setBonusBalance(resp?.data?.money?.amount)
          } catch (err) {
            console.error(err)
          }
        })
  }

  loadVouchers() {
    return this.http.wst.get('manager/vouchers').then((resp) => this.setVouchers(resp.data as Money))
  }

  @action
  setAccount(account: AccountJSON) {
    this.account = new Account(account)
  }

  @action
  setBonusBalance(bonus: number) {
    this.bonusBalance = bonus
  }

  @action
  setAccountBalance(money: Money) {
    this.account.money = money
  }

  @action
  setAccountExposure(money: Money) {
    this.account.exposure = money
  }

  @action
  setFrontNotificationText(notification: FrontNotification) {
    this.frontNotificationText = notification.text
  }
  @action
  setFrontNotificationId(notification: FrontNotification) {
    this.frontNotificationId = notification.user_message_id
  }

  @action
  setVouchers(money: Money) {
    this.vouchers = money
  }

  activateAccount(account: AccountJSON): Promise<void> {
    return this.http.wst.get(`contract/active/${account.id}`).then(() => this.setAccount(account))
  }
  activateSwitchAccount(id?: number): Promise<void> {
    return this.http.wst.get(`account/active/${id}`).then( res => this.setAccount(res.data))
  }

  getSwitchAcc(bonus = false) {
    if (this.accounts.length === 2) {
      return this.accounts[0].type !== (bonus ? 'playeraccount' : 'bonusaccount') ? this.accounts[1] : this.accounts[0]
    }

    return this.accounts[0]
  }

  getBonusAcc() {
    if (this.accounts.length === 2) {
      return this.accounts[0].type === 'bonusaccount' ? this.accounts[0] : this.accounts[1]
    }

    return null
  }

  getPlayerAcc() {
    return this.accounts[0].type === 'playeraccount' ? this.accounts[0] : this.accounts[1]
  }

  async load() {
    return this.http.wst
      .get<IUserJSON>('user')
      .then(({ data }) => {
        runInAction(() => {
          this.role = data.roles.length ? <Role>data.roles[data.roles.length - 1] : 'guest'
          this.roles = data.roles
          this.statuses = data.status
          this.id = data.id
          this.username = data.username
          this.email = data.email
          this.tfaEnabled = data.tfaEnabled
          this.lastLogin = data.lastLogin
          this.location = new Location(data.profile.location)
          this.settings = new Settings(data.settings)
          this.licenseId = data.licenses && data.licenses[0].id
          this.accounts = data.accounts.filter((acc) => acc.status === '__active')
          this.createdAt = data.createdAt
          this.btag = data.btag

          if (this.role === 'masteragent' || this.role === 'supermasteragent') {
            const origin =
              window.location.hostname === 'localhost' ? `//${this.config.domain}` : window.location.origin
            window.location.href = origin + '/agent'
          }

          if (this.role === 'partner') {
            const origin =
              window.location.hostname === 'localhost' ? `//${this.config.domain}` : window.location.origin
            window.location.href = origin + '/partner'
          }
        })
      })
      .catch((error) => {
        if (this.config.front['login.required']) {
          if (this.history.location.search.indexOf('modal=') === -1 && this.history.location.pathname.indexOf('player/reset_password') === -1) {
            this.history.push('/login')
          }
        }
        runInAction(() => {
          this.role = 'guest'
        })
      })
  }

  /// HELPERS

  @action('clear session')
  clear() {
    this.lastLogin = null
    this.logInError = null
    this.licenseId = null
    this.role = 'guest'
  }
}

interface IUserJSON {
  id: number
  roles: string[]
  status: UserStatus[]
  username: string
  email: string
  tfaEnabled: boolean
  lastLogin: string
  profile: {
    location: LocationJSON
  }
  licenses: {
    id: number
  }[]
  accounts: AccountJSON[]
  settings: SettingsJSON
  createdAt: string
  btag?: string
}
