import React, { useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { RouteChildrenProps } from 'react-router'
import { AppContext } from 'core'

interface Props extends RouteChildrenProps<{ slug?: string }> {}

export const TelegramLink = observer(({ match }: Props) => {
  const { http, history, user} = useContext(AppContext)
  useEffect(() => {
      if (!user.isLoggedIn) {
          history.push('/casino?modal=auth')
      } else {
          getLink()
      }
  })

  function getLink() {
    http.wst
      .post<any>(`user/get-telegram-token`, {})
      .then(({ data }) => {
         window.location.href = data.url
      })
      .catch(() => history.push('/login'))
  }
  return (
      <div className="preloader"/>
  )
})
