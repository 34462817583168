import {Google} from './services/google'
import {ConfigStore} from '../../core'
import {UserStore} from '../../core'
import {Amplitude} from './services/amplitude'
export interface AnaliticsServiceInterface {
    track: (event: IEvent, userId: string, btag: string, callback?: (param: any) => void) => void
}
export interface IEvent {
    name: EventNames
    action: string
}
export enum EventNames {
    siteOpen = 'siteOpen',
    openPhoneVerification = 'openPhoneVerification',
    confirmPhone = 'confirmPhone',
    openRegister = 'openRegister',
    register = 'register',
    openDeposit = 'openDeposit',
    depositDone = 'depositDone',
    depositFailed = 'depositFailed',
    registrationErrors = 'registrationErrors',
    betCreated = 'betCreated',
    login = 'login',
    lastWinner = 'lastWinner',
    andarBanner = 'Andar Bahar Banner',
    excnahgeBanner = 'Exchange Banner',
    bblBanner = 'BBL FINAL Banner',
    aviatorBanner = 'Aviator Banner',
    referBanner = 'Refer Banner',
    bannerClick = 'bannerClick',
    newRapidBannerClick = 'NewRapidBannerClick',
    playedGamesClick = 'PlayedGamesClick',
    kabaddiBanner = 'Kabaddi Baner',
    fancyClick = 'Fancy selection click',
    enterCasino = 'Enter Casino',
    enterExchange = 'Enter Exchange',
    enterSportsBook = 'Enter SportsBook',
    logout = 'logout',
    randomGamesClick = 'RandomGamesClick',
    referClick = 'ReferClick',
    referCopyLinkClick = 'ReferCopyLinkClick',
    submitRegister = 'SubmitRegister',
    enterOtp = 'EnterOtp',
    finalStepInput = 'FinalStepInput',
    sendPhone = 'SendPhone'
}

export type Services = 'google' | 'amplitude' | 'amplitude_new'
export class AnaliticsService {

    private services: {
        [key: string]: AnaliticsServiceInterface
    }

    constructor(private config: ConfigStore, private user: UserStore) {
        this.services = {}
        this.services.google = (new Google(this.config))
        this.services.amplitude = (new Amplitude(this.config))
    }

    track(type: Services, event: IEvent, callback?: (param?: any) => void) {
        const userId = this.user && this.user.id ? this.user.id.toString() : ''
        const btag = localStorage.getItem('btag') || ''
        switch (type) {
            case 'google':
                this.services.google.track(event, userId, btag, callback)
                break
            case 'amplitude_new':
                this.services.amplitude.track(event, userId, btag, callback)
                break
          default : return null
        }
    }
}
