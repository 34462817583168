import ReactGA from 'react-ga'
import { AppStore, ConfigStore, createStore } from 'core'

import { initializeTagManager } from 'utils/script/google-tag-manager.script'
import {getCookie} from '../utils/storage'

export class ProjectLoader {
  public app: AppStore

  constructor(public config: ConfigStore) {
    this.app = createStore(config)
    this.app.history.listen(this.handleLocationChange.bind(this))
    this.app.http.onWstUnauthorized(this.handleUserStatusChange.bind(this))
    if (process.env.STAGE === 'prod') {
      if (config.data.project === 'bitomania') {
        initializeTagManager('GTM-MXJKF52')
      } else if (config.data.project === 'fairwaybet') {
        ReactGA.initialize('UA-168878351-1')
      } else if (config.data.project === '24betting') {
        initializeTagManager('GTM-5T4DH3N')
      } else if (config.data.project === 'testdb') {
        initializeTagManager('GTM-5T4DH3N')
      } else if (config.data.project === 'realwin') {
        initializeTagManager('GTM-TLJH9J9')
      } else if (config.data.project === 'indiabet24') {
        initializeTagManager('G-PGNV1WJY6R')
      } else if (config.data.project === 'iworld247') {
        initializeTagManager('UA-201377342-1')
      } else if (config.data.project === 'sportswin247') {
        initializeTagManager('UA-201377342-1')
      } else {
        ReactGA.initialize('UA-142142399-1')
      }
    }
  }

  public async load() {
    this.loadThemeAssets(this.config.data.frontConfiguration.theme)
    this.loadProjectAssets(this.config.data.project)
    if (getCookie('mark')) {
      await this.app.user.load()
    }
    await this.app.i18n.load()
    return Promise.resolve()
  }

  private loadThemeAssets(theme: string) {
    return import(`assets/themes/${theme}/index.css`)
  }

  private loadProjectAssets(project: string) {
    return import(`projects/${project}/assets/style.css`)
  }

  private handleLocationChange(props: any) {
    this.app.user.validateRoute(props.pathname)
    window.scroll(0, 0)
    if (process.env.STAGE === 'prod') {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  private handleUserStatusChange() {
    if (this.app.user.isLoggedIn) {
      this.app.user.logOut()
    }
  }
}
