export function initializeTagManager(gtmId: string) {
  const dataLayer = 'dataLayer'
  const w = window as any

  w[dataLayer] = w[dataLayer] || []
  w[dataLayer].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

  const script = document.createElement('script')

  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&l=${dataLayer}`

  document.head.appendChild(script)
}
