import axios from 'axios'
import { action, computed, observable, runInAction } from 'mobx'
import { Config, ConfigJSON, ExchangeSource, FetchedExchangeSources } from './config'

export class ConfigStore {
  @observable data: Config
  @observable isDarkTheme: boolean = true

  constructor(private stage: Stage = 'dev', public domain?: string) {}

  @computed
  get url() {
    return this.stage === 'prod'
      ? 'https://configurator.bettings.ch/api'
      : 'http://configurator.dev.bettings.ch/api'
  }

  get front() {
    return this.data.frontConfiguration
  }

  @computed
  get params() {
    let params: {
      domain?: string
      secret_key?: string
    } = {}
    if (this.domain) {
      params.domain = this.domain
    }
    if (process.env.NODE_ENV === 'development') {
      params.secret_key = '12389FJAF8u0ufjsHHK3010283019SFAC'
    }
    return params
  }

  @action.bound
  setDomain(domain?: string) {
    if (!domain) return
    this.domain = domain
  }

  @action.bound
  async load() {
    let params = {}
    if (window.location.origin.includes('real')) {
      params = {...this.params, ...{'locale-token': 'br'}}
    } else {
      params = this.params
    }
    return axios
      .get('configuration', {baseURL: this.url, params})
      .then((response: { data: ConfigJSON }) => {
        runInAction(() => {
          this.data = this.parseConfig(response.data)
        })
      })
  }


  @action
  changeTheme() {
    this.isDarkTheme = !this.isDarkTheme
  }

  @action.bound
  parseConfig(config: ConfigJSON): Config {
    return {
      // Hardcoded variables
      eventRowSizeEnabled: true,
      // Dynamic
      project: config.name,
      domain: config.domain,
      https: config.https,
      paymentsEnabled: (config.bundles.payment && config.bundles.payment.enabled) || false,
      bonusEnabled: (config.bundles.bonus && config.bundles.bonus.enabled) || false,
      achievementsEnabled: (config.bundles.achievement && config.bundles.achievement.enabled) || false,
      achievements2Enabled: (config.bundles.achievement2 && config.bundles.achievement2.enabled) || false,
      millionerEnabled: (config.bundles.millioner && config.bundles.millioner.enabled) || false,
      agentSystemEnabled: (config.bundles.agent && config.bundles.agent.enabled) || false,
      shop: (config.bundles.shop && config.bundles.shop.enabled) || false,
      oneClickBettingEnabled: config.frontConfiguration['oneClickBetting.enabled'] || false,
      confirmBeforeBet: config.frontConfiguration['exchanges.bets.confirm.enabled'] || false,
      confirmBeforeBetDefault: config.frontConfiguration['exchanges.bets.confirm.default'] || false,
      multimarketsEnabled: config.frontConfiguration['exchanges.multimarkets.enabled'] || false,
      exchangeEnabled:
        config.bundles.betfair !== undefined ||
        config.bundles.exchange !== undefined ||
        config.bundles.match_book !== undefined ||
        config.bundles.dabba !== undefined ||
        false,
      displayCurrency:
        config.frontConfiguration.displayCurrency === undefined
          ? true
          : config.frontConfiguration.displayCurrency,
      casinoEnabled:
        !!config.bundles.flash_games ||
        !!config.bundles.microgaming ||
        !!config.bundles.soft_swiss ||
        !!config.bundles.fundist,
      documentsVerificationEnabled:
        config.bundles.user && config.bundles.user.verification.indexOf('document_verification') !== -1,
      ...(config.bundles.sport_book && {
        sportsbook: {
          url: config.frontConfiguration['sportsbook.api']
            ? config.frontConfiguration['sportsbook.api']
            : 'https://dev.sbapi.bettings.ch/api',
          parlayBonus: config.bundles.sport_book.bonus_parlay,
          token: config.bundles.sport_book.token,
          sbstlapi: config.bundles.sport_book.api,
          betSubmitDelay: config.frontConfiguration['sportsbook.betslip.submitDelay'],
        },
      }),
      ...(config.bundles.exchange && {
        selfexchange: {
          url: config.frontConfiguration['exchange.api'],
          sources: config.frontConfiguration['exchange.sources'].reduce(
            (sources: ExchangeSource[], source: FetchedExchangeSources) => {
              if (source.prelive.source) {
                sources.push({ ...source.prelive })
              }

              if (source.live.source) {
                sources.push({ ...source.live, live: true })
              }
              return sources
            },
            []
          ),
          token: config.bundles.exchange.token,
          showLiquidityEverywhere: config.frontConfiguration['exchange.showLiquidityEverywhere'],
          betSubmitDelay: config.frontConfiguration['exchange.betslip.submitDelay'],
        },
      }),
      ...(config.bundles.betfair && {
        betfair: {
          url: config.frontConfiguration['betfair.api'],
          accountId: config.bundles.betfair.account_id || '',

          prefixUrl: config.frontConfiguration['betfair.prefixUrl'],
          preliveTitle: config.frontConfiguration['betfair.prelive.title'],
          isLiveEnabled: config.frontConfiguration['betfair.live.enabled'],
          liveTitle: config.frontConfiguration['betfair.live.title'],

          lowLiquidityCheck: config.frontConfiguration['betfair.lowLiquidityCheck.enabled'],
          lowLiquidityMinAvailable: config.frontConfiguration['betfair.lowLiquidityCheck.minTotalAvailable'],
          lowLiquidityMinMatched: config.frontConfiguration['betfair.lowLiquidityCheck.minTotalMatched'],
          showLiquidityEverywhere: config.frontConfiguration['betfair.showLiquidityEverywhere'],
          betSubmitDelay: config.frontConfiguration['betfair.betslip.submitDelay'],
        },
      }),
      ...(config.bundles.match_book && {
        matchbook: {
          url: config.frontConfiguration['matchbook.api'],
          token: config.bundles.match_book.token,

          prefixUrl: config.frontConfiguration['matchbook.prefixUrl'],
          preliveTitle: config.frontConfiguration['matchbook.prelive.title'],
          isLiveEnabled: config.frontConfiguration['matchbook.live.enabled'],
          liveTitle: config.frontConfiguration['matchbook.live.title'],

          showLiquidityEverywhere: config.frontConfiguration['matchbook.showLiquidityEverywhere'],
          betSubmitDelay: config.frontConfiguration['matchbook.betslip.submitDelay'],
        },
      }),
      ...(config.bundles.dabba && {
        dabba: {
          url: config.frontConfiguration['dabba.api'],
          accountId: config.bundles.dabba.account_id || '',

          prefixUrl: config.frontConfiguration['dabba.prefixUrl'],
          preliveTitle: config.frontConfiguration['dabba.prelive.title'],
          isLiveEnabled: config.frontConfiguration['dabba.live.enabled'],
          liveTitle: config.frontConfiguration['dabba.live.title'],

          showLiquidityEverywhere: false,
          betSubmitDelay: config.frontConfiguration['dabba.betslip.submitDelay'],
        },
      }),
      ...(config.bundles.session_games && {
        sessionGames: {
          url: config.frontConfiguration['sessionGames.api'],
          token: config.bundles.session_games.token || '',
          betSubmitDelay: config.frontConfiguration['sessionGames.betslip.submitDelay'],
        },
      }),
      frontConfiguration: config.frontConfiguration,
    }
  }
}
